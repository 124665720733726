import React from "react";

const SectionContent = ({
    children,
    title = '',
    titleAlign = 'center',
    titleSize = 'default',
    customClass = ''
}) => (
    <section className={`section-content ${customClass}`}>
        <h6 className={`${ titleAlign === 'center' ? 'text-center' : 'text-left' } ${titleSize === 'default' ? '' : 'text-bigger'}`}>{title}</h6>
        {children}
    </section>
);

export default SectionContent;
