import React from 'react';
import SectionNotFound from "../../../../libs/components/Sections/SectionNotFound";

const NotFoundPage = () => (
    <main>
        <SectionNotFound />
    </main>
);

export default NotFoundPage;
