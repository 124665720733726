import React from "react";

const SectionNotFound = () => (
    <section className="section-not-found">
        <h2>404</h2>
        <h3>Page Not Found</h3>
    </section>
);

export default SectionNotFound;
