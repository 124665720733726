import React from "react";
import SectionContent from "../../../../libs/components/Sections/SectionContent";
import imgSrc from "../images/screenshot.png";

const Homepage = () => (
    <main>
        <SectionContent
            title='The latest Results from around the world.'
            titleAlign={'left'}
        >
            <img src={imgSrc} alt="Screenshot"/>
        </SectionContent>
        <SectionContent title='Your most comprehensive source of lucky numbers results.'>
            <p className={'text-center'}>
                Subscribe to our newsletter and get access to game results from all over the world
                <a href="https://wa.me/27661800842/?text=Start" className='btn-link'> Send me Results</a>
            </p>
        </SectionContent>
    </main>
);

export default Homepage;
